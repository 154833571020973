import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useState } from "react";
import * as Yup from "yup";

import Button from "../../atoms/button/Button";
import TextFormControl from "../../atoms/text-form-control/TextFormControl";
import { useLead, useSetLead } from "../../features/leads-funnel/application/lead-use-cases";
import { Lead } from "../../features/leads-funnel/domain/lead";
import PageStepForm, { PageStepFormDataElement } from "../../organisms/page-step-form/PageStepForm";
import { Events, track } from "../../utils/analytics";
import { rollbar } from "../../utils/rollbar";
import * as styles from "./Name.module.scss";

interface PetNameFormValues {
  petName: string;
}

interface Image {
  default: string;
}

const petNameSVG: Image = require("../../images/pages/leads/hello.svg") as Image;

const petNameSVGSrc: string = petNameSVG.default;

const Name = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const lead: Lead = useLead();
  const setLead = useSetLead();
  const nextUrl = `/${i18n.language}/onboarding/health_questionnaire/pre_existences/`;

  const [validateOnMount, setValidateOnMount] = useState(true);

  const PetNameFormData: PageStepFormDataElement = {
    initialValues: {
      petName: lead.petName,
    },
    validateOnMount,
    validationSchema: Yup.object({
      petName: Yup.string().trim().required(t("common.validation.required")),
    }),
    handleSubmit: (
      values: PetNameFormValues,
      { setSubmitting }: FormikHelpers<PetNameFormValues>
    ): void => {
      if (!values.petName) {
        return;
      }

      setSubmitting(true);
      setLead(values)
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            setSubmitting(false);

            return;
          }

          track(Events.FORM_ANSWERED, {
            question: "Pet name",
            answer: values.petName,
          });

          void navigate(nextUrl);
        })
        .catch(() => {
          rollbar.warn("There was an error setting the lead.");
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<PetNameFormValues>) => {
      const { isSubmitting, isValid, submitCount } = props;
      const handleClick = () => {
        setValidateOnMount(submitCount !== 0);
      };

      return (
        <Form className={cn(styles.petNameForm)}>
          <TextFormControl
            name="petName"
            label={t("onboarding.name.field.title")}
            isFocusedOnRender
          />
          <Button
            type="submit"
            disabled={isSubmitting || !isValid}
            isLoading={isSubmitting}
            onClick={handleClick}
          >
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("onboarding.name.title")}
      image={petNameSVGSrc}
      formData={PetNameFormData}
    />
  );
};
export default Name;
